var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"talent"},[(_vm.$apollo.queries.artists.loading)?_c('Loader',{attrs:{"color":"#FF035A","size":"50"}}):_c('div',{staticClass:"content"},[_c('div',{staticClass:"left-panel"},[_c('div',{staticClass:"filters"},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Search.svg")}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],attrs:{"type":"search","placeholder":"Search by name"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}})]),_c('CustomSelect',{attrs:{"default":"Sort: A to Z","options":[
            {
              value: 'az',
              label: 'Sort: A to Z'
            },
            {
              value: 'za',
              label: 'Sort: Z to A'
            }
          ]},on:{"setValue":function (value) { return (_vm.sortBy = value); }}})],1),_c('div',{staticClass:"artists-list"},_vm._l((_vm.filteredArtists),function(artist){return _c('div',{key:artist.id,staticClass:"artist-card",class:{ active: artist.id === _vm.active },on:{"click":function($event){return _vm.goToContact(artist.id)}}},[_c('div',{staticClass:"avatar"},[(artist.avatar_url)?_c('div',{staticClass:"picture"},[_c('img',{attrs:{"src":artist.avatar_url}})]):_c('div',{staticClass:"initials"},[_vm._v(" "+_vm._s(_vm._f("initials")(artist.name))+" ")])]),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(artist.name)+" ")])])}),0)]),_c('ContactPreview',{attrs:{"artists":_vm.artists}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }