<template>
  <div class="talent">
    <Loader v-if="$apollo.queries.artists.loading" color="#FF035A" size="50" />
    <div v-else class="content">
      <div class="left-panel">
        <div class="filters">
          <div class="search">
            <div class="icon">
              <img src="../assets/icons/Search.svg" />
            </div>
            <input
              type="search"
              v-model="searchQuery"
              placeholder="Search by name"
            />
          </div>
          <CustomSelect
            default="Sort: A to Z"
            @setValue="value => (sortBy = value)"
            :options="[
              {
                value: 'az',
                label: 'Sort: A to Z'
              },
              {
                value: 'za',
                label: 'Sort: Z to A'
              }
            ]"
          />
        </div>
        <div class="artists-list">
          <div
            v-for="artist in filteredArtists"
            :key="artist.id"
            class="artist-card"
            :class="{ active: artist.id === active }"
            @click="goToContact(artist.id)"
          >
            <div class="avatar">
              <div class="picture" v-if="artist.avatar_url">
                <img :src="artist.avatar_url" />
              </div>
              <div class="initials" v-else>
                {{ artist.name | initials }}
              </div>
            </div>
            <div class="name">
              {{ artist.name }}
            </div>
          </div>
        </div>
      </div>
      <ContactPreview :artists="artists" />
    </div>
  </div>
</template>

<script>
import ContactPreview from "@/components/ContactPreview.vue";
import CustomSelect from "@/components/Select.vue";
import Loader from "@/components/Loader.vue";

import GET_ARTISTS from "@/api/queries/GET_ARTISTS.gql";

export default {
  name: "Contacts",
  components: {
    ContactPreview,
    CustomSelect,
    Loader
  },
  data() {
    return {
      searchQuery: "",
      sortBy: "az"
    };
  },
  computed: {
    active() {
      return this.$route.query.artist;
    },
    filteredArtists() {
      const multiple = this.sortBy === "az" ? 1 : -1;

      const artists = this.artists.filter(el =>
        el.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );

      return artists.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1 * multiple;
        if (nameA > nameB) return 1 * multiple;
        return 0;
      });
    }
  },
  filters: {
    initials(name) {
      return name
        .split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    goToContact(id) {
      this.$router.push({ name: "Contacts", query: { artist: id } });
    }
  },
  apollo: {
    artists: {
      query: GET_ARTISTS,
      variables() {
        return { current: this.$store.getters["account/getArtistId"] };
      },
      update: ({ artists }) =>
        artists.map(el => ({
          ...el,
          name: el.name || el.artist_users[0].user.email
        })),
      fetchPolicy: "cache-and-network"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.talent {
  width: 100%;
  height: 100%;
  .content {
    display: grid;
    grid-template-columns: 400px 1fr;
    width: 100%;
    height: 100%;
    .left-panel {
      height: 100%;
      .filters {
        display: flex;
        padding: 20px 30px;
        .search {
          flex: 1;
          display: flex;
          align-items: center;
          .icon {
            img {
              width: 20px;
              height: 20px;
            }
          }
          input {
            flex: 1;
            margin-left: 8px;
            border: none;
            outline: none;
            color: $text-additional;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: -0.22px;
          }
        }
      }
      .artists-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100vh - 134px);
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: $accent rgba(#cdcccc, 0.3);
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          @include background-opacity(#cdcccc, 0.3);
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $accent;
          border-radius: 5px;
        }
        .artist-card {
          display: flex;
          align-items: center;
          width: calc(100% - 60px);
          padding: 8px;
          background-color: #f6f6f6;
          border: 2px solid #f6f6f6;
          border-radius: 10px;
          margin-bottom: 20px;
          cursor: pointer;

          &.active,
          &:hover {
            border: 2px solid #838383;
            box-shadow: 0px 16px 24px rgba(59, 59, 59, 0.08);
          }
          &.active {
            background: #ffffff;
            border: 2px solid $accent;
          }
          .avatar {
            .picture {
              img {
                height: 57px;
                width: 57px;
                object-fit: cover;
                border-radius: 50%;
              }
            }
            .initials {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 57px;
              width: 57px;
              font-family: sans-serif;
              font-size: 20px;
              background-color: $black;
              border: 1px solid $accent;
              color: $white;
              border-radius: 50%;
            }
          }
          .name {
            margin-left: 16px;
            flex: 1;
            color: $text;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: -0.2475px;
            margin-right: 4px;
          }
        }
        &:after {
          content: "";
          flex: 1;
        }
      }
    }
  }
}
</style>
